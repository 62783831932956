import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Alert, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

import "bootstrap/dist/css/bootstrap.min.css";
import "./report.css";
import NavBarHeader from "./../../components/NavBar/NavBar";
import PerformanceReportListView from "./../../components/PerformanceReport/PerformanceReportListView.js";
import PerformanceReportListPrint from "./../../components/PerformanceReport/PerformanceReportListPrint";
// import NavBar from "../../../components/NavBar/index";
const APIURL = "https://performance-report-api.newsdirect.com/";

const Performance = () => {
  const targetRef = useRef();
  const printRef = useRef();

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [useAuth, setUseAuth] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();
  const articlesData = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("useAuth"));
    if (items) {
      setUseAuth(items);
    } else {
      navigate("/");
    }
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const article_url =
      articlesData.ProjectDetails && articlesData.ProjectDetails.article_url
        ? articlesData.ProjectDetails.article_url
        : "";
    const query = article_url.replace("https://newsdirect.com/news/", "");
    // const query ='dbv_technologies';
    fetch(APIURL + "report?query=" + query, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setReportData(data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        //  console.log('error ***** ',JSON.stringify(error))
      });
  }, []);

  const options = {
    // default is `save`
    method: "open",
    // resolution: Resolution.HIGH,
    page: {
      margin: Margin.MEDIUM,
      format: "letter",
      orientation: "landscape",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
  };

  const DownloadPdfClick = async () => {
    setIsLoading(true);
    try {
      const Printed = await generatePDF(targetRef, { filename: "Performance Report.pdf" });
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // Hide the loader
      setIsLoading(false);
      setModalShow(false);
    }
  };

  const PrintClick = async () => {
    setIsLoading(true);
    try {
      const Printed = await generatePDF(getTargetElement, options);
      console.log("printed", Printed);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // Hide the loader
      setIsLoading(false);
      setModalShow(false);
    }
  };

  // you can use a function to return the target element besides using React refs
  const getTargetElement = () => document.getElementById("content-id");

  return (
    <div>
      {/* <button onClick={() => generatePDF(getTargetElement, options)}>Open PDF</button> */}
      {/* <button onClick={() => generatePDF(targetRef, {filename: 'page.pdf'})}>Download PDF</button> */}
      {/* <button onClick={() => generatePDF(getTargetElement, options)}>Open PDF</button>
      <div id="content-id">
        Content to be generated to PDF
        <div class="myelement1" style={{ display: 'block' }}>My div has an inline style.</div>

      </div> */}
      <NavBarHeader data={useAuth} />

      {isLoading ? (
        <div className="loading">
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="container">
          <h3 className="mt-5">Performance Report</h3>

          <div
            className="event-schedule-area-two bg-color pad100"
            ref={targetRef}
            id="content-id"
          >
            <div
              className="container"
              style={{ marginTop: "30px", paddingTop: "20px" }}
            >
              <div className="row">
                <div className="col-lg-2 text-center">
                  <img
                    src={articlesData.ProjectDetails.logo_url}
                    alt="Thumbnail"
                    style={{
                      maxWidth: "144px",
                      height: "70px",
                      display: "block",
                      borderRadius: "10%",
                    }}
                  />
                </div>

                <div className="col-lg-10">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      cursor: "pointer",
                      lineHeight: 1,
                      color: "#a0acb0",
                    }}
                  >
                    {moment(articlesData.published_date).format("ll") +
                      " " +
                      moment(articlesData.published_date).format("LT")}
                  </span>
                  <br />

                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "28px",
                      cursor: "pointer",
                      lineHeight: 1.37,
                    }}
                  >
                    {articlesData.title}
                  </span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-3"
                  style={{
                    border: "solid 2px #e7eff3",
                    alignItems: "center",
                    marginTop: 30,
                    marginBottom: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <div className="row" style={{ margin: "30px" }}>
                    <div className="col-lg-12 text-center">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: 1,
                          color: "#a0acb0",
                        }}
                      >
                        Total Number of Postings
                      </span>
                      <br></br>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "52px",
                          lineHeight: 1.37,
                        }}
                      >
                        {reportData.length}
                      </span>
                    </div>
                    {/* <div className="col-lg-6 text-center">
                        <span style={{ fontWeight: 'bold', fontSize: '14px', lineHeight: 1, color: '#a0acb0' }}>
                          Total Link Clicks
                        </span>
                        <br></br>
                        <span style={{ fontWeight: 'bold', fontSize: '52px', lineHeight: 1.37 }}>
                          65
                        </span>
                      </div> */}
                  </div>
                </div>
                <div className="col-lg-5" style={{}}></div>
                <div
                  className="col-lg-3"
                  style={{ alignContent: "space-around" }}
                  onClick={handleShow}
                >
                  <Button
                    type="submit"
                    style={{
                      height: "50px",
                      borderRadius: "4px",
                      color: "#ffffff",
                      width: "100%",
                      backgroundColor: "#485985",
                    }}
                  >
                    Share Posting (US) Report
                  </Button>
                </div>
              </div>
              {/* <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div className="col-lg-2" style={{ paddingRight: '0px' }}>

                    <Button type="submit" style={{ height: '50px', borderRadius: '0px', backgroundColor: '#f0f4f7', color: '#13224b', width: '100%', border: 'solid 1px #dddddd' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                      </svg>
                      List
                    </Button>

                  </div>
                  <div className="col-lg-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                    <Button type="submit" style={{ height: '50px', borderRadius: '0px', backgroundColor: '#ffffff', color: '#13224b', width: '100%', border: 'solid 1px #dddddd' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                      </svg>
                      Card
                    </Button>
                  </div>
                  <div className="col-lg-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                    <Button type="submit" style={{ height: '50px', borderRadius: '2px', color: '#ffffff', width: '80%', marginLeft: '20px', backgroundColor: '#ed3424' }}>
                      Filters
                    </Button>
                  </div>
                  <div className="col-lg-3" style={{}}></div>
                  <div className="col-lg-3" style={{}}>

                    <Button type="submit" style={{ height: '50px', borderRadius: '4px', color: '#ffffff', width: '100%', backgroundColor: '#485985' }}>
                      Share Posting (US) Report
                    </Button>
                  </div>
                </div> */}

              <PerformanceReportListView reportData={reportData} />
              {/* <PerformanceReportListPrint reportData={reportData}/> */}
            </div>
          </div>
          <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Share Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="row"
                style={{
                  alignItems: "center",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
                onClick={DownloadPdfClick}
              >
                <div className="col-lg-1" style={{ padding: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"
                    />
                  </svg>
                </div>
                <div
                  className="col-lg-11"
                  style={{ padding: "10px", paddingLeft: "20px" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: 1,
                      color: "#524655",
                      cursor: "pointer",
                    }}
                  >
                    Download PDF
                  </div>
                </div>
              </div>
              {/* <div className="row" style={{ alignItems: 'center', textAlign: 'left', paddingLeft: '10px' }}>


            <div className="col-lg-1" style={{ padding: '10px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1" />
              </svg>
            </div>
            <div className="col-lg-11" style={{ padding: '10px', paddingLeft: '20px' }}>
              <div style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: 1, color: '#524655' }}>
                Download Excel
              </div>
            </div>
          </div> */}

              <div
                className="row"
                style={{
                  alignItems: "center",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
                onClick={PrintClick}
              >
                <div className="col-lg-1" style={{ padding: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-printer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                  </svg>
                </div>
                <div
                  className="col-lg-11"
                  style={{ padding: "10px", paddingLeft: "20px" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: 1.38,
                      color: "#524655",
                      cursor: "pointer",
                    }}
                  >
                    Print...
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Performance;
